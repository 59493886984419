import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Helmet from 'react-helmet'
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/get-started"

import headerImg from '../images/ketopersonalplan.jpg';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Get A Personalized Keto Diet Plan" 
      description="Get started with the ketogenic diet today, and get a personal diet plan tailored to your specific taste, designed by professional chefs and nutritionalists."
      image={headerImg}
    />
    <Helmet>
      <meta name="google-site-verification" content="Ip30Jirqcs79qXVbUs4lLkXfEvYSzo1lLS7g3vevR5Y" />
    </Helmet>
    <Navigation />
    <Header />
    <Features />
    <GetStarted />
    <Footer />
  </Layout>
)

export default IndexPage

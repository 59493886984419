import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

export const EmailFormContainer = styled(BackgroundImage)`
  margin: 120px 0;
  padding: 60px 0 80px 0;
  background-position: 80% 50%;
  background-size: cover;
  
  clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 calc(100% - 5vw));

  h2, p {
    text-align: center;
    color: #fff;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  max-width: 820px;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

export const EmailInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
`

export const EmailButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
    width: 100%
  }
`

export const FormSubtext = styled.p`
  text-align: center;
`

export const FormMessage = styled.p`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
`

import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from "gatsby-background-image";

import './style.css';

const Video = () => {
  const [clicked, setClicked] = useState(false);
  const onKeyPress = event => (event.key === 'Enter') && setClicked(true);
  return (
    <StaticQuery
      query={query}
      render={data => {
        const imageData = data.bg.childImageSharp.fluid;
        const containerStyle = clicked ? {backgroundSize: '0%'} : {}
       
        return (
          <BackgroundImage className="video-container" style={containerStyle} fluid={imageData} tag="div">
            <div className="diet-video embed-container">
              { !clicked &&
                <div className="play-button"
                  role="button"
                  tabIndex="0"
                  aria-label="Play Video"
                  onClick={setClicked}
                  onKeyPress={onKeyPress}/>
              }

              {clicked &&
                <iframe src="https://player.vimeo.com/video/315431309?playsinline=0&autoplay=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
                  title="Keto Personal Plan"></iframe>
              }
            </div>
          </BackgroundImage>
        )
      }}
    />
  )
}

const query = graphql`
  query {
    bg: file(relativePath: { eq: "keto-diet-video.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Video;
/* eslint-disable jsx-a11y/accessible-emoji */

import React from "react"
import styled from "styled-components"

import Video from './video/index.js'
import EmailForm from './email-form'
import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <Container>
      <SectionTitle>Why The Keto Diet?</SectionTitle>
      <Video/>
    </Container>

    <EmailForm />

    <Container>
      <SectionTitle>Get A Custom Keto Meal Plan</SectionTitle>
      <p style={{textAlign: 'center'}}>
        Create your very own keto diet plan based on your food preferences, daily activity levels, height, weight and target weight goals.
      </p>

      <FeaturesGrid>
        <FeatureItem>
          <FeatureIcon>🗓 🥣</FeatureIcon>
          <FeatureTitle>Eight-Week Meal Plan</FeatureTitle>
          <FeatureText>
            Created based with help from expert nutritionists, trainers, and chefs.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>🙌 😇</FeatureIcon>
          <FeatureTitle>Tailored to Your Needs</FeatureTitle>
          <FeatureText>
            Calorie and macronutrient content tailored to your own situation and goals.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>🥓 🥦 🥑</FeatureIcon>
          <FeatureTitle>Variety of Foods</FeatureTitle>
          <FeatureText>
            Wide variety of different foods for complete nutrients that neever will get boring.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>🎉 ☺️ ✨</FeatureIcon>
          <FeatureTitle>Just for You!</FeatureTitle>
          <FeatureText>
            We'll make your diet enjoyable based on your personal tastes and help you stay on track.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>👩‍🍳 🍴</FeatureIcon>
          <FeatureTitle>Detailed Recipes</FeatureTitle>
          <FeatureText>No cooking experience needed. Step-by-step guides to make meal prep simple.</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>🌟 😀</FeatureIcon>
          <FeatureTitle>&nbsp;Customize Every Meal</FeatureTitle>
          <FeatureText>
            Options for how to make every recipie suited to your taste buds.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </Container>
  </Section>
)

export default Features

const SectionTitle = styled.h2`
  color: ${props => props.theme.color.primary};
  margin: 0 auto 40px;
  text-align: center;
  font-weight: bold;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  margin-top: 100px;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureIcon = styled.span`
  display: block;
  letter-spacing: 0px;
  margin-bottom: 5px;
  padding-top: 40px;
  font-size: 40px;
`

const FeatureTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: normal;
`

const FeatureText = styled.p`
  text-align: center;
`

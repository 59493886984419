import React, {useState} from 'react'
import { graphql, StaticQuery } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Container } from "../../global"
import { EmailFormContainer, Form, EmailInput, EmailButton, FormSubtext, FormMessage } from './style'

const EmailForm = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const onChangeEmail = e => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await addToMailchimp(email);
    setMessage(response.msg);
    setSubmitted(true);
  }

  return (
    <StaticQuery
      query={query}
      render={data => {
        const imageData = data.bg.childImageSharp.fluid;
        
        return (
          <EmailFormContainer id="free-recipes" fluid={imageData} tag="div">
            <Container>
              <h2>Get Our Free <br/> Keto Recipe E-Book</h2>
              <Form onSubmit={handleSubmit}>
                <label htmlFor="email-input" style={{position: 'absolute', left: '-100vw'}}>Your email</label>
                <EmailInput id="email-input" placeholder="Your email" onChange={onChangeEmail} />
                <EmailButton>Get My Free Recipies</EmailButton>
              </Form>
      
              { !isSubmitted &&
                <FormSubtext>
                  You'll love these delicious recipes that make keto easy&nbsp;and&nbsp;fun!
                </FormSubtext>
              }
      
              { isSubmitted && 
                <FormMessage dangerouslySetInnerHTML={{__html: message}}></FormMessage>
              }
            </Container>
          </EmailFormContainer>
        )
      }}
    />
  )
}

const query = graphql`
  query {
    bg: file(relativePath: { eq: "steak-keto-meal.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default EmailForm;

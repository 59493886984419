import React from "react"
import { graphql, StaticQuery } from 'gatsby'

import { Section } from "../../global"
import { StyledSection, GetStartedContainer, GetStartedTitle, TryItButton, Subtitle } from './style'

const GetStarted = () => (
  <StaticQuery
    query={query}
    render={data => {
      const imageData = data.bg.childImageSharp.fluid;

      return (
        <StyledSection fluid={imageData} tag={Section}>
          <GetStartedContainer>
            <GetStartedTitle>Try a Personal Keto Diet Plan  <br/>  for 7 Days. Only $1.00!</GetStartedTitle>
            <TryItButton href="/signup/">Get My Meal Plan</TryItButton>
            <Subtitle>It Takes Less Than 5 Minutes.</Subtitle>
          </GetStartedContainer>
        </StyledSection>
      )
    }}
  />
)

const query = graphql`
  query {
    bg: file(relativePath: { eq: "keto-diet-trial-background.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default GetStarted;
import React from "react"
import styled from "styled-components"

import headerImg from '../../images/ketopersonalplan.jpg';

import { Container } from "../global"

const Header = () => (
  <HeaderWrapper id="top">
    <Container>
      <Flex>
        <HeaderTextGroup>
          <h1>
            A keto diet plan
            <br />
            personalized just for you.
          </h1>
          <Subtext>
            Created with the help of professional nutritionists, personal trainers, and chefs.
          </Subtext>

          <HeaderButtonLink href="/signup/">Get My Keto Plan</HeaderButtonLink>
          <SecondaryButtonLink href="#free-recipes">Try Our Free Recipes</SecondaryButtonLink>
        </HeaderTextGroup>
      </Flex>
    </Container>
  </HeaderWrapper>
)

export default Header

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0;
  position: relative;
  background-image: url(${headerImg});
  background-position: 50% 50%;
  background-size: cover;
  color: #fff;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.xxs}) {
    padding: 100px 0 160px 0;
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`

`

const HeaderButtonLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;

  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;

  height: 60px;
  width: 200px;
  text-align: center;
  line-height: 60px;
  margin-left: 8px;

  background: ${props => props.theme.color.secondary};

  border-radius: 4px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  && {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 10px;
  }
`

const SecondaryButtonLink = styled(HeaderButtonLink)`
  background: none;
  border: 1px solid #fff;
`

const Subtext = styled.p`
  font-family: bold;
  text-size: 30px;
  
  && {
    margin-bottom: 20px;
  }
`